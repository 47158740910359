import React, { useLayoutEffect, useState } from 'react'
import './award.scss'
import loadable from '@loadable/component'
import Marked from 'react-markdown'
const Fade = loadable(() => import('../transition/Fade'))

const Awards = React.memo(({awardsData, description}) => {

  const [awardsDetails, setAwardsDetails] = useState([]);

  useLayoutEffect(() => {
    let awards = []
    awardsData.map(award => {
      return awards.push(award)
    })
    setAwardsDetails(awards)
  }, [awardsData])

  const formatArray = (awardsArray) => {
    const details = awardsArray
    let formattedArray = []
    while (details.length > 0) {
      let array = []
      array = details.splice(0, 3)
      formattedArray.push(array)
    }
    return formattedArray;
  }

  const awards = awardsArray => {
    return awardsArray.map(({ node }) => {
      return (
        <img
          loading="lazy"
          src={node.frontmatter.featuredImage}
          alt={node.frontmatter.title}
          key={node.fields.slug}
          className="award-img"
        />
      )
    })
  }
  return (
    <div
      className="awards-section d-lg-flex justify-content-lg-between"
      id="common-px"
    >
      <div className="title-container" id="clients">
        <Fade timemout={500}>
          <h1>Awards</h1>
        </Fade>
        <Fade timemout={1000}>
          <p className="title-dec">
            <Marked
              parserOptions={{ commonmark: true }}
              source={description}
            />
          </p>
        </Fade>
      </div>
      <div className="img-main-container">
        {formatArray(awardsDetails).map((awardsArray, index) => {
          return (
            <div
              className="award-img-container d-lg-flex align-items-lg-center justify-content-lg-between"
              key={index}
            >
              {awards(awardsArray)}
            </div>
          )
        })}
      </div>
    </div>
  )
})

export default Awards
